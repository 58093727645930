import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Main } from "./Main";
import { Navigation } from "./Navigation";
import { About } from "./About";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/navigation" element={<Navigation />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
