import React from "react";
import { Link } from "react-router-dom";

import "./About.css";

export const About = () => {
  return (
    <div className="about-container">
      <Link to="/">Home</Link>&nbsp;&nbsp;&nbsp;
      <Link to="/navigation">Links Page</Link>
      <h1>Bonnie Grubbs</h1>
      <a
        className="link"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.linkedin.com/in/bonnie-grubbs-169094139/"
      >
        Linkedin Profile
      </a>
      <a
        className="link"
        href="https://github.com/bonniegrubbs"
        target="_blank"
        rel="noopener noreferrer"
      >
        GitHub
      </a>
      <div className="summary-container">
        <h2>Summary</h2>
        <p>
          I'm a collaborative full-stack developer targeting a mid level
          opportunity in software engineering. Specifically looking to integrate
          skills into a team, find solutions to new challenges, and develop
          products that create value.
        </p>
        <ul className="summary-list">
          <li>
            Software Engineer with a BS/IT - emphasis in software development,
            offering a strong foundation in object-oriented design across
            multiple platforms.
          </li>
          <li>
            Trained in object-oriented programming; developing working software,
            debugging and testing code, and setting up development environments.
          </li>
          <li>
            Quickly learn and master new technologies; has worked with Eclipse,
            Android Studio, Visual Studio, Visual Studio Code, RubyMine, Docker,
            SQL Server, Oracle Database Management Tools, SSMS, AWS, Azure,
            Java, C#, HTML, CSS, SCSS, XML, JSON, REST, and more.
          </li>
          <li>
            Experience in Ruby on Rails, Java, React, JavaScript, MySQL,
            Cypress.io, and agile development - utilizing visual studio code and
            git, in Linux and Windows based environments.
          </li>
        </ul>
      </div>
      <div className="work-exp-container">
        <h2>Work Experience</h2>
        <h4>
          Colorado Springs Utilities - Application Software Developer &#40;May
          2020 - Present&#41;
        </h4>
        <p>
          Colorado Springs Utilities is a community-owned enterprise that works
          to provide utility services to the city of Colorado Springs, Colorado.
          At Colorado Springs Utilities I work for the Customer Portfolio team
          which services customer facing applications and their respective
          databases.
        </p>
        <p>Highlights and responsibilities of this position:</p>
        <ul>
          <li>
            Developed new full-stack solutions to replace aging applications and
            technology.
          </li>
          <li>
            Maintained and fixed bugs for existing applications and features.
          </li>
          <li>
            Write unit, integration, and front-end tests using Jest, Enzyme,
            JUnit.
          </li>
          <li>Utilize GitHub Enterprise.</li>
          <li>
            Perform Peer Reviews and maintain code through git repositories.
          </li>
          <li>
            Tech Stack: Java, Spring Boot, JavaScript, React, Jest, Enzyme,
            Oracle Databases, Jenkins, Git, and GitHub.
          </li>
        </ul>
        <h4>
          HiringThing – Software Engineer &#40;October 2018 – April 2020&#41;
        </h4>
        <p>
          HiringThing is a fully remote, cloud-based software company that helps
          companies post jobs online, manage applicants, and hire great
          employees. At HiringThing I am part of a technology-first team
          dedicated to building fun and easy to use applicant tracking software.
          We keep in touch using Slack and regular video conferences. Our team
          meets daily and routinely connects with other teams during standard
          business hours.
        </p>
        <p>Highlights and responsibilities of this position:</p>
        <ul>
          <li>
            Developed new front-end features for HiringThing's applicant
            tracking system.
          </li>
          <li>Maintained and fixed bugs for existing features.</li>
          <li>
            Write unit, integration, and front-end tests using Mocha, Chai,
            Enzyme, Cypress.io, and Percy for Cypress.
          </li>
          <li>Utilize Jira to track work items.</li>
          <li>
            Perform Peer Reviews and maintain code through git repositories.
          </li>
          <li>
            Tech Stack: React w/ Redux, Mocha w/ Chai, Ruby on Rails, MySQL,
            Cypress.io, Amazon Web Services, Docker, JQuery, BackBone, Git,
            GitHub, and Jira.
          </li>
        </ul>
        <h3>Education</h3>
        <p>2016 - 2018 Colorado State University</p>
        <p>BS/IT in Computer Programming 3.91 GPA</p>
        <p>1999 - 2001 Florida Southwestern State College</p>
        <p>AA in Business 3.5 GPA</p>
        <h3>Technology Summary</h3>
        <p>
          .Net, Agile Development, Ajax, Amazon Web Services, Android Studio,
          Android Widgets, Azure, BackBone, C#, C++, CSS, Cypress.io, Docker,
          Eclipse, Firebase, FTP, Git, GitHub, GitLens, HTML, Java, JavaScript,
          Jira, JQuery, JSON, JSX, Linux, Mac, Mocha w/ Chai, MS Office Suite,
          MySQL, Object-Oriented Design, Percy, PHP, React w/ Redux, REST, Ruby
          on Rails, RubyMine, SCSS, SQL Server, SQL, SSMS, Visual Studio, Visual
          Studio Code, Web Services, Windows Server 2008 & 2012, Windows,
          WordPress, XML
        </p>
      </div>
      <Link to="/">Home</Link>&nbsp;&nbsp;&nbsp;
      <Link to="/navigation">Links Page</Link>
    </div>
  );
};
