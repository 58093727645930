import React from "react";
import { Link } from "react-router-dom";

import "./Navigation.css";

export const Navigation = () => {
  return (
    <div className="navigation-container">
      <h1 className="title-text">Where would you like to go?</h1>
      <div className="links-container">
        <li className="nav-link">
          <Link className="nav-link" to="/about">
            About
          </Link>
        </li>
        <li className="nav-link">Travels</li>
        <li className="nav-link">Blog</li>
        <li>
          <Link className="nav-link" to="/">
            Home
          </Link>
        </li>
      </div>
    </div>
  );
};
