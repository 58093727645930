import React from "react";
import { Link } from "react-router-dom";
import "../Main/Main.css";
import logo from "./pixzen-logo.png";

export const Main = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1 className="intro-text">My Coding Playground</h1>
        <Link className="click-text" to="/Navigation">
          Click here to begin
        </Link>
      </header>
    </div>
  );
};
